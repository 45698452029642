import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { Link } from "../components/CustomLinks"
import FloatingSearch from "../components/floatingSearch"

import companyImage from "../../static/home/helmet.png"
import cultureImage from "../../static/home/2025-eog-culture.png"
import sustainabilityImage from "../../static/home/globe-2020.png"
import investorsImage from "../../static/home/2023-delivering-eog-value-proposition.png"
import logo_color from "../../static/images/logo_color.png"
import "react-aspect-ratio/aspect-ratio.css"

const Page = () => {
  const [logo, setLogo] = useState(false)
  const [company, setCompany] = useState(false)
  const [culture, setCulture] = useState(false)
  const [sustainability, setSustainability] = useState(false)
  const [investors, setInvestors] = useState(false)
  const startAnimations = async () => {
    setTimeout(() => {
      setLogo(true)
    }, 250)
    setTimeout(() => {
      setCompany(true)
    }, 1000)
    setTimeout(() => {
      setCulture(true)
    }, 1250)
    setTimeout(() => {
      setSustainability(true)
    }, 1500)
    setTimeout(() => {
      setInvestors(true)
    }, 1750)
  }
  useEffect(() => {
    startAnimations()
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>EOG Resources, Inc.</title>
      </Helmet>
      <div className="max-width-container absolute desktop-only">
        <FloatingSearch />
      </div>
      <section className="page home-page last-page">
        <div className="max-width-container ">
          <Link className={`main-logo ${logo ? "animate" : ""}`} to="/">
            <img src={logo_color} alt="logo" />
          </Link>
          <section className="menu-section first">
            <Link
              to="/company/"
              className="home-button"
              style={{
                color: "#c82c1e",
                opacity: company ? 1 : 0,
              }}
            >
              <img
                className={`home-button-image company ${
                  company ? "animate" : ""
                }`}
                src={companyImage}
                alt="company"
              />
              Company
            </Link>
            <Link
              to="/culture/"
              className="home-button"
              style={{ color: "#ff6d00", opacity: culture ? 1 : 0 }}
            >
              <img
                className={`home-button-image culture ${
                  culture ? "animate" : ""
                }`}
                src={cultureImage}
                alt="culture"
              />
              Culture
            </Link>
          </section>
          <section className="menu-section second">
            <Link
              to="/sustainability/"
              className="home-button"
              style={{ color: "#18b77b", opacity: sustainability ? 1 : 0 }}
            >
              <img
                className={`home-button-image sustainability ${
                  sustainability ? "animate" : ""
                }`}
                src={sustainabilityImage}
                alt="sustainability"
              />
              Sustainability
            </Link>
            <a
              href="https://investors.eogresources.com/"
              className="home-button"
              style={{
                color: "#2f6ff3",
                opacity: investors ? 1 : 0,
              }}
            >
              <img
                className={`home-button-image investors ${
                  investors ? "animate" : ""
                }`}
                src={investorsImage}
                alt="investors"
              />
              Investors
            </a>
          </section>
        </div>
      </section>
    </Layout>
  )
}
export default Page
